/* .react-select-style { 
    z-index: 1200 !important;
} */

.select__menu {
    z-index: 1600 !important;
}

.swal2-container {
    z-index: 1600 !important;
}

body {
    scrollbar-width: none; /*Firefox*/
}

/*Webkit(Chrome, Android browser, Safari, Edge...)*/
body::-webkit-scrollbar {
    width: 0;
}